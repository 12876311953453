const cropIcon = [
  require("../Assests/crops/acai_palm.jpg"),
  require("../Assests/crops/alfalfa.jpg"),
  require("../Assests/crops/almonds.jpg"),
  require("../Assests/crops/apple.jpg"),
  require("../Assests/crops/apricot.jpg"),
  require("../Assests/crops/artichoke.jpg"),
  require("../Assests/crops/avocado.jpg"),
  require("../Assests/crops/banana.jpg"),
  require("../Assests/crops/barley.jpg"),
  require("../Assests/crops/basil.jpg"),
  require("../Assests/crops/bean.jpg"),
  require("../Assests/crops/beet.jpg"),
  require("../Assests/crops/bermuda_grass.jpg"),
  require("../Assests/crops/calabash.jpg"),
  require("../Assests/crops/bitter_gourd.jpg"),
  require("../Assests/crops/bitter_melon.jpg"),
  require("../Assests/crops/bitter_squash.jpg"),
  require("../Assests/crops/blueberry.jpg"),
  require("../Assests/crops/broccoli.jpg"),
  require("../Assests/crops/cabbage.jpg"),
  require("../Assests/crops/calabash.jpg"),
  require("../Assests/crops/cannabis.jpg"),
  require("../Assests/crops/cantaloupe.jpg"),
  require("../Assests/crops/carambola.jpg"),
  require("../Assests/crops/carrot.jpg"),
  require("../Assests/crops/cassava.jpg"),
  require("../Assests/crops/cauliflower.jpg"),
  require("../Assests/crops/celery.jpg"),
  require("../Assests/crops/cherry.jpg"),
  require("../Assests/crops/chickpea.jpg"),
  require("../Assests/crops/chili.jpg"),
  require("../Assests/crops/chrysanthemum.jpg"),
  require("../Assests/crops/citrus.jpg"),
  require("../Assests/crops/cocoa_bean.jpg"),
  require("../Assests/crops/coconut_palm.jpg"),
  require("../Assests/crops/coffee_bean.jpg"),
  require("../Assests/crops/coriander.jpg"),
  require("../Assests/crops/corn.jpg"),
  require("../Assests/crops/cotton.jpg"),
  require("../Assests/crops/cucumber.jpg"),
  require("../Assests/crops/date_palm.jpg"),
  require("../Assests/crops/dill.jpg"),
  require("../Assests/crops/eggplant.jpg"),
  require("../Assests/crops/fig.jpg"),
  require("../Assests/crops/garlic.jpg"),
  require("../Assests/crops/geranium.jpg"),
  require("../Assests/crops/grape.jpg"),
  require("../Assests/crops/guava.jpg"),
  require("../Assests/crops/hibiscus.jpg"),
  require("../Assests/crops/hops.jpg"),
  require("../Assests/crops/kiwi.jpg"),
  require("../Assests/crops/lettuce.jpg"),
  require("../Assests/crops/litchi.jpg"),
  require("../Assests/crops/mango.jpg"),
  require("../Assests/crops/mint.jpg"),
  require("../Assests/crops/myrtle.jpg"),
  require("../Assests/crops/nectarine.jpg"),
  require("../Assests/crops/oat.jpg"),
  require("../Assests/crops/oil_palm.jpg"),
  require("../Assests/crops/olives.jpg"),
  require("../Assests/crops/onion.jpg"),
  require("../Assests/crops/oregano.jpg"),
  require("../Assests/crops/ornamental_palm.jpg"),
  require("../Assests/crops/papaya.jpg"),
  require("../Assests/crops/parsley.jpg"),
  require("../Assests/crops/passion_fruit.jpg"),
  require("../Assests/crops/pea.jpg"),
  require("../Assests/crops/peach.jpg"),
  require("../Assests/crops/peanuts.jpg"),
  require("../Assests/crops/pear.jpg"),
  require("../Assests/crops/pecan.jpg"),
  require("../Assests/crops/pepper.jpg"),
  require("../Assests/crops/persimmon.jpg"),
  require("../Assests/crops/petunia.jpg"),
  require("../Assests/crops/plum.jpg"),
  require("../Assests/crops/pomegranate.jpg"),
  require("../Assests/crops/potato.jpg"),
  require("../Assests/crops/pumpkin.jpg"),
  require("../Assests/crops/raspberry.jpg"),
  require("../Assests/crops/rice.jpg"),
  require("../Assests/crops/roses.jpg"),
  require("../Assests/crops/sage.jpg"),
  require("../Assests/crops/sorghum.jpg"),
  require("../Assests/crops/soy.jpg"),
  require("../Assests/crops/spinach.jpg"),
  require("../Assests/crops/squash.jpg"),
  require("../Assests/crops/strawberry.jpg"),
  require("../Assests/crops/sugarcane.jpg"),
  require("../Assests/crops/sunflower.jpg"),
  require("../Assests/crops/sweet_potato.jpg"),
  require("../Assests/crops/tarragon.jpg"),
  require("../Assests/crops/tea.jpg"),
  require("../Assests/crops/tobacco.jpg"),
  require("../Assests/crops/tomato.jpg"),
  require("../Assests/crops/turnip.jpg"),
  require("../Assests/crops/vanilla.jpg"),
  require("../Assests/crops/walnut.jpg"),
  require("../Assests/crops/watermelon.jpg"),
  require("../Assests/crops/wheat.jpg"),
  require("../Assests/crops/zucchini.jpg"),
  require("../Assests/crops/alocasia.jpg"),
  require("../Assests/crops/aloe_vera.jpg"),
  require("../Assests/crops/blackberry.jpg"),
  require("../Assests/crops/agave.jpg"),
  require("../Assests/crops/anthurium.jpg"),
  require("../Assests/crops/arugula.jpg"),
  require("../Assests/crops/asparagus.jpg"),
  require("../Assests/crops/azalea.jpg"),
  require("../Assests/crops/laurel.jpg"),
  require("../Assests/crops/black_pepper.jpg"),
  require("../Assests/crops/bougainvillea.jpg"),
  require("../Assests/crops/boxwood.jpg"),
  require("../Assests/crops/cactus.jpg"),
  require("../Assests/crops/canna_lily.jpg"),
  require("../Assests/crops/cashew.jpg"),
  require("../Assests/crops/chard.jpg"),
  require("../Assests/crops/chestnut.jpg"),
  require("../Assests/crops/chinese_evergreen.jpg"),
  require("../Assests/crops/clover.jpg"),
  require("../Assests/crops/coleus.jpg"),
  require("../Assests/crops/crab_apple.jpg"),
  require("../Assests/crops/jade_plant.jpg"),
  require("../Assests/crops/croton.jpg"),
  require("../Assests/crops/currants.jpg"),
  require("../Assests/crops/dahlia.jpg"),
  require("../Assests/crops/desert_rose.jpg"),
  require("../Assests/crops/dieffenbachia.jpg"),
  require("../Assests/crops/dipladenia.jpg"),
  require("../Assests/crops/dracaena.jpg"),
  require("../Assests/crops/durian.jpg"),
  require("../Assests/crops/eucalyptus.jpg"),
  require("../Assests/crops/ficus.jpg"),
  require("../Assests/crops/fiddle_leaf_fig.jpg"),
  require("../Assests/crops/hydrangea.jpg"),
  require("../Assests/crops/impatiens.jpg"),
  require("../Assests/crops/ivy.jpg"),
  require("../Assests/crops/jackfruit.jpg"),
  require("../Assests/crops/jasmine.jpg"),
  require("../Assests/crops/kalanchoe.jpg"),
  require("../Assests/crops/kale.jpg"),
  require("../Assests/crops/lavender.jpg"),
  require("../Assests/crops/lemon_blam.jpg"),
  require("../Assests/crops/lemongrass.jpg"),
  require("../Assests/crops/loquat.jpg"),
  require("../Assests/crops/magnolia.jpg"),
  require("../Assests/crops/maple.jpg"),
  require("../Assests/crops/marigold.jpg"),
  require("../Assests/crops/melon.jpg"),
  require("../Assests/crops/millet.jpg"),
  require("../Assests/crops/pothos.jpg"),
  require("../Assests/crops/money_tree.jpg"),
  require("../Assests/crops/monstera.jpg"),
  require("../Assests/crops/okra.jpg"),
  require("../Assests/crops/orchid.jpg"),
  require("../Assests/crops/peace_lily.jpg"),
  require("../Assests/crops/pineapple.jpg"),
  require("../Assests/crops/plumeria.jpg"),
  require("../Assests/crops/poinsettia.jpg"),
  require("../Assests/crops/quince.jpg"),
  require("../Assests/crops/radish.jpg"),
  require("../Assests/crops/rosemary.jpg"),
  require("../Assests/crops/schefflera.jpg"),
  require("../Assests/crops/snake_plant.jpg"),
  require("../Assests/crops/soursop.jpg"),
  require("../Assests/crops/succulent.jpg"),
  require("../Assests/crops/taro.jpg"),
  require("../Assests/crops/yucca.jpg"),
  require("../Assests/crops/zinnia.jpg"),
  require("../Assests/crops/mustard.jpg"),
  require("../Assests/crops/rapeseed.jpg"),
  require("../Assests/crops/cyclamen.jpg"),
];

export const cropIcons = {
  "Acai palm": cropIcon[0],
  Agave: cropIcon[103],
  Alfalfa: cropIcon[1],
  Almonds: cropIcon[2],
  Alocasia: cropIcon[100],
  "Aloe vera": cropIcon[101],
  Anthurium: cropIcon[104],
  Apple: cropIcon[3],
  Apricot: cropIcon[4],
  Artichoke: cropIcon[5],
  Arugula: cropIcon[105],
  Asparagus: cropIcon[106],
  Avocado: cropIcon[6],
  Azalea: cropIcon[107],
  Banana: cropIcon[7],
  Barley: cropIcon[8],
  Basil: cropIcon[9],
  Bean: cropIcon[10],
  Beet: cropIcon[11],
  "Bermuda grass": cropIcon[12],
  Blackberry: cropIcon[102],
  "Black pepper": cropIcon[109],
  "Bottle gourd": cropIcon[13],
  Bougainvillea: cropIcon[110],
  Boxwood: cropIcon[111],
  "Bitter gourd": cropIcon[14],
  "Bitter melon": cropIcon[15],
  "Bitter squash": cropIcon[16],
  Blueberry: cropIcon[17],
  Broccoli: cropIcon[18],
  Cabbage: cropIcon[19],
  Cactus: cropIcon[112],
  "Canna lily": cropIcon[113],
  Calabash: cropIcon[20],
  Cannabis: cropIcon[21],
  Cantaloupe: cropIcon[22],
  Carambola: cropIcon[23],
  Carrot: cropIcon[24],
  Cashew: cropIcon[114],
  Cassava: cropIcon[25],
  Cauliflower: cropIcon[26],
  Celery: cropIcon[27],
  Chard: cropIcon[115],
  Cherry: cropIcon[28],
  Chestnut: cropIcon[116],
  "Chinese evergreen": cropIcon[117],
  Chickpea: cropIcon[29],
  Chilli: cropIcon[30],
  Chrysanthemum: cropIcon[31],
  Citrus: cropIcon[32],
  Clover: cropIcon[118],
  Cocoa: cropIcon[33],
  "Coconut palm": cropIcon[34],
  Coffee: cropIcon[35],
  Coleus: cropIcon[119],
  Coriander: cropIcon[36],
  Corn: cropIcon[37],
  Cotton: cropIcon[38],
  "Crab apple": cropIcon[120],
  Croton: cropIcon[122],
  Cucumber: cropIcon[39],
  Currants: cropIcon[123],
  Cyclamen: cropIcon[170],
  Dahlia: cropIcon[124],
  "Desert rose": cropIcon[125],
  Dieffenbachia: cropIcon[126],
  Dates: cropIcon[40],
  Dill: cropIcon[41],
  Dipladenia: cropIcon[127],
  Dracaena: cropIcon[128],
  Durian: cropIcon[129],
  Eggplant: cropIcon[42],
  Eucalyptus: cropIcon[130],
  Ficus: cropIcon[131],
  "Fiddle-leaf fig": cropIcon[132],
  Fig: cropIcon[43],
  Garlic: cropIcon[44],
  Geranium: cropIcon[45],
  Grape: cropIcon[46],
  Guava: cropIcon[47],
  Hibiscus: cropIcon[48],
  Hops: cropIcon[49],
  Hydrangea: cropIcon[133],
  Impatiens: cropIcon[134],
  Ivy: cropIcon[135],
  Jackfruit: cropIcon[136],
  "Jade plant": cropIcon[121],
  Jasmine: cropIcon[137],
  Kalanchoe: cropIcon[138],
  Kale: cropIcon[139],
  Kiwi: cropIcon[50],
  Laurel: cropIcon[108],
  Lavender: cropIcon[140],
  "Lemon balm": cropIcon[141],
  Lemongrass: cropIcon[142],
  Lettuce: cropIcon[51],
  Litchi: cropIcon[52],
  Loquat: cropIcon[143],
  Magnolia: cropIcon[144],
  Mango: cropIcon[53],
  Maple: cropIcon[145],
  Marigold: cropIcon[146],
  Melon: cropIcon[147],
  Millet: cropIcon[148],
  Mint: cropIcon[54],
  "Money tree": cropIcon[150],
  Monstera: cropIcon[151],
  Mustard: cropIcon[168],
  Myrtle: cropIcon[55],
  Nectarine: cropIcon[56],
  Oat: cropIcon[57],
  "Oil palm": cropIcon[58],
  Okra: cropIcon[152],
  Olives: cropIcon[59],
  Onion: cropIcon[60],
  Orchid: cropIcon[153],
  Oregano: cropIcon[61],
  "Ornamental palm": cropIcon[62],
  Papaya: cropIcon[63],
  Parsley: cropIcon[64],
  "Passion fruit": cropIcon[65],
  Pea: cropIcon[66],
  Peach: cropIcon[67],
  "Peace lily": cropIcon[154],
  Peanuts: cropIcon[68],
  Pear: cropIcon[69],
  Pecan: cropIcon[70],
  Pepper: cropIcon[71],
  Persimmon: cropIcon[72],
  Petunia: cropIcon[73],
  Pineapple: cropIcon[155],
  Plum: cropIcon[74],
  Plumeria: cropIcon[156],
  Poinsettia: cropIcon[157],
  Pomegranate: cropIcon[75],
  Potato: cropIcon[76],
  Pothos: cropIcon[149],
  Pumpkin: cropIcon[77],
  Quince: cropIcon[158],
  Radish: cropIcon[159],
  Rapeseed: cropIcon[169],
  Raspberry: cropIcon[78],
  Rice: cropIcon[79],
  Rosemary: cropIcon[160],
  Roses: cropIcon[80],
  Sage: cropIcon[81],
  Schefflera: cropIcon[161],
  "Snake plant": cropIcon[162],
  Sorghum: cropIcon[82],
  Soursop: cropIcon[163],
  Soy: cropIcon[83],
  Spinach: cropIcon[84],
  Squash: cropIcon[85],
  Strawberry: cropIcon[86],
  Succulent: cropIcon[164],
  Sugarcane: cropIcon[87],
  Sunflower: cropIcon[88],
  "Sweet potato": cropIcon[89],
  Taro: cropIcon[165],
  Tarragon: cropIcon[90],
  Tea: cropIcon[91],
  Tobacco: cropIcon[92],
  Tomato: cropIcon[93],
  Turnip: cropIcon[94],
  Vanilla: cropIcon[95],
  Walnut: cropIcon[96],
  WaterMelon: cropIcon[97],
  Wheat: cropIcon[98],
  Yucca: cropIcon[166],
  Zinnia: cropIcon[167],
  Zucchini: cropIcon[99],
};
